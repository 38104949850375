import PreFooter from "./pre-footer";
import MainFooter from "./main-footer";
import PostFooter from "./post-footer";
import Quicklinks from "./quicklinks";

export default function Footer({footer, targetAudience}) {
    return (
        <>
            <footer>
                <Quicklinks targetAudience={targetAudience}/>
                <PreFooter footer={footer} targetAudience={targetAudience}/>
                <MainFooter footer={footer} targetAudience={targetAudience}/>
                <PostFooter footer={footer} targetAudience={targetAudience}/>
            </footer>
        </>
    );
}
