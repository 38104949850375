import "../styles/style.scss";
import Layout from "../layouts/default";
import {GeneralContextProvider} from "../store/general";
import dynamic from "next/dynamic";
import {useEffect} from "react";
import {gtmConfig} from "../config";
import {gtm} from "../lib/gtm";
import {useRouter} from "next/router";
import {countryMenus} from "../lib/countryConfig";

const ProgressBar = dynamic(() => import("../components/progress-bar"), {
    ssr: false,
});

function MyApp({Component, pageProps}) {
    const router = useRouter();

    useEffect(() => {
        gtm.initialize(gtmConfig);
    }, []);

    useEffect(() => {
        const handleRouteChange = (url) => {
            gtm.push({event: "virtual_page_view", page: url});
        };

        router.events.on("routeChangeComplete", handleRouteChange);

        return () => {
            router.events.off("routeChangeComplete", handleRouteChange);
        };
    }, [router.events]);

    return (
        <GeneralContextProvider>
            <ProgressBar/>
            <Layout menus={countryMenus} page={pageProps.page ? pageProps.page : null}>
                <Component {...pageProps} />
            </Layout>
        </GeneralContextProvider>
    );
}

export default MyApp;
