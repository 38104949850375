import Link from "next/link";
import Image from "next/image";

export default function MainFooter({footer, targetAudience}) {
    return (
        <div className={`main-footer ${targetAudience === 'business' ? 'main-footer--brown' : ''}`}>
            <Link href="/">
                <a className="main-footer__logo"/>
            </Link>
            <div className="container">
                <div className="frame">
                    <div className="bit-xxs-12">
                        {footer.main_footer.slogan && (
                            <p className="main-footer__slogan">{footer.main_footer.slogan}</p>
                        )}
                        <div className="main-footer__socials flex flex--justify-center tw-gap-x-5">
                            {footer.main_footer.instagram && (
                                <a href={footer.main_footer.instagram} target="_blank">
                                    <i className="fab fa-instagram"/>
                                </a>
                            )}
                            {footer.main_footer.facebook && (
                                <a href={footer.main_footer.facebook} target="_blank">
                                    <i className="fab fa-facebook"/>
                                </a>
                            )}
                            {footer.main_footer.linkedin && (
                                <a href={footer.main_footer.linkedin} target="_blank">
                                    <i className="fab fa-linkedin"/>
                                </a>
                            )}
                        </div>
                    </div>
                </div>
                <div className="frame tw-justify-center">
                    <div className="bit-xxs-12 bit-sm-4 bit-md-3 bit-lg-2 [&_a]:tw-underline">
                        {footer.main_footer.address_and_contact && (
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: footer.main_footer.address_and_contact,
                                }}
                            />
                        )}
                    </div>
                    <div className="bit-xxs-12 bit-sm-4 bit-md-3 bit-lg-2 hidden-mobile">
                        <div className="main-footer__menu-list">
                            <p className="pb-0">
                                <strong>{footer.main_footer.quicklinks_menu_title}</strong>
                            </p>
                            {footer.main_footer.quicklinks_menu_list.length &&
                                footer.main_footer.quicklinks_menu_list.map((item) => {
                                    item = item.link;
                                    return (
                                        <p className="pb-0" key={item.title}>
                                            <Link href={item.url} passHref>
                                                <a target={item.target}>{item.title}</a>
                                            </Link>
                                        </p>
                                    );
                                })}
                        </div>
                    </div>
                    <div className="bit-xxs-12 bit-sm-4 bit-md-3 bit-lg-2">
                        <div className="main-footer__menu-list">
                            <p className="pb-0">
                                <strong>{footer.main_footer.about_menu_title}</strong>
                            </p>
                            {footer.main_footer.about_menu_list.length &&
                                footer.main_footer.about_menu_list.map((item) => {
                                    item = item.link;
                                    return (
                                        <p className="pb-0" key={item.title}>
                                            <Link href={item.url} passHref>
                                                <a target={item.target}>{item.title}</a>
                                            </Link>
                                        </p>
                                    );
                                })}
                        </div>
                    </div>
                    {footer.main_footer.reviews &&
                        <div className="bit-xxs-12 bit-lg-6">

                            <p className="tw-pb-0">
                                <strong>{footer.main_footer.reviews_title}</strong>
                            </p>

                            <div className="frame">
                                {footer.main_footer.reviews.map((review) => {
                                    return (
                                        <div className="bit-xxs-12 bit-sm-4" key={review.text}>
                                            {review.image && review.link && (
                                                <Link href={review.link}>
                                                    <a className="tw-block tw-h-[100px] tw-flex tw-items-center tw-justify-center tw-pb-10"
                                                       target="_blank">
                                                        <figure
                                                            className="tw-w-[160px] tw-h-[75px] tw-flex tw-items-center tw-justify-center">
                                                            <Image src={review.image}/>
                                                        </figure>
                                                    </a>
                                                </Link>
                                            )}
                                            {review.text && (
                                                <div
                                                    className="tw-text-[14px] tw-leading-tight tw-font-medium tw-text-center"
                                                    dangerouslySetInnerHTML={{
                                                        __html: review.text,
                                                    }}
                                                ></div>
                                            )}
                                        </div>
                                    );
                                })}
                            </div>

                        </div>
                    }

                </div>
            </div>
        </div>
    );
}
