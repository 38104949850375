import {useContext} from "react";
import GeneralContext from "../store/general";
import Link from "next/link";
import {countryMySamSamLink} from "../lib/countryConfig";

export default function MobileMenu({menus}) {
    const generalContext = useContext(GeneralContext);

    function toggleMobileMenu() {
        generalContext.toggleMobileMenu();
    }

    return (
        <div
            className={`mobile-menu ${
                generalContext.mobileMenuOpen ? "mobile-menu--is-open" : ""
            }`}
        >
            <button onClick={toggleMobileMenu} className="mobile-menu__close">
                <i className="fas fa-times"/>
            </button>
            <div className="mobile-menu__background-logo"/>
            <div className="mobile-menu__content">
                <Link href="/">
                    <a className="mobile-menu__logo" onClick={toggleMobileMenu}/>
                </Link>
                <ul>
                    {menus["mobile-menu"].items.map((item) => {
                        return (
                            <li key={item.url}>
                                <Link href={item.url} target={item.target}>
                                    <a onClick={toggleMobileMenu}>{item.title}</a>
                                </Link>
                            </li>
                        );
                    })}
                </ul>
                {countryMySamSamLink.url && <div className="mt-20">
                    <Link href={countryMySamSamLink.url}>
                        <a className="mobile-menu__account-link" onClick={toggleMobileMenu}>
                            <i className="fas fa-user-circle"/>
                            <span>{countryMySamSamLink.title}</span>
                        </a>
                    </Link>
                </div>}
            </div>
        </div>
    );
}
