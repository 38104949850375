import Header from "../components/header";
import SeoHead from "../components/seo-head";
import Footer from "../components/footer";
import GeneralContext from "../store/general";
import {useContext, useEffect} from "react";

export default function Layout({menus, children, page}) {
    const generalContext = useContext(GeneralContext);

    useEffect(() => {
        let breadcrumbs =
            page && page.seo
                ? page.seo.schema["@graph"].find((i) => i["@type"] === "BreadcrumbList")
                : [];

        generalContext.setBreadcrumbs(breadcrumbs);
    }, [page]);

    return (
        <>
            {page && page.seo && <SeoHead obj={page.seo}/>}
            {page && page.header && <Header menus={menus} header={page.header} targetAudience={page.target_audience}/>}
            <main>{children}</main>
            {page && page.footer && <Footer footer={page.footer} targetAudience={page.target_audience}/>}
        </>
    );
}
