import Image from 'next/image'

export default function ResponsiveImage({image, classes, layout = 'responsive'}) {
    return (
        <Image src={image.src}
               width={layout === 'responsive' ? image.width : null}
               height={layout === 'responsive' ? image.height : null}
               placeholder="blur"
               blurDataURL={image.placeholder}
               layout={layout}
               className={classes}
        />
    )
}