import NavbarDesktop from "./navbar-desktop";
import NavbarMobile from "./navbar-mobile";
import MobileMenu from "./mobile-menu";

export default function Header({menus, header, targetAudience}) {
    return (
        <header>
            <NavbarDesktop menus={menus} header={header} targetAudience={targetAudience}/>
            <NavbarMobile header={header} targetAudience={targetAudience}/>
            <MobileMenu menus={menus}/>
        </header>
    );
}
