import ResponsiveImage from "./modules/responsive-image";
import Button from "./button";
import LogoSlider from "./logo-slider";
import Link from "next/link";
import {Swiper, SwiperSlide} from "swiper/react";
import {Pagination} from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import classNames from "../lib/classNames";

const swiperSettings = {
    slidesPerView: 1,
    spaceBetween: 0,
    breakpoints: {
        1024: {
            spaceBetween: 30,
            slidesPerView: 2,
        },
    },
};

export default function PreFooter({footer, targetAudience}) {
    return (
        <div className={classNames(
            "pre-footer",
            targetAudience === 'business' ? 'pre-footer--brown' : '',
        )}>
            <div
                className={`pre-footer__shape ${
                    footer.pre_footer.share
                        ? "pre-footer__shape--small"
                        : footer.pre_footer.logo_slider
                            ? "pre-footer__shape--large"
                            : footer.pre_footer.dakcheck ||
                            footer.pre_footer.blog ||
                            footer.pre_footer.magazine
                                ? ""
                                : "pre-footer__shape--small"
                }`}
            />
            <div className="container">
                <div className="frame frame--justify-sm-center">
                    {footer.pre_footer.dakcheck && (
                        <div className="bit-xxs-12 bit-sm-8 bit-md-6 pt-0 pb-0 mb-30">
                            <div className="pre-footer__dakcheck">
                                {footer.pre_footer.dakcheck.image && (
                                    <figure className="pre-footer__dakcheck__image">
                                        <ResponsiveImage
                                            image={footer.pre_footer.dakcheck.image}
                                            classes="pre-footer__dakcheck__image__img"
                                            layout="fill"
                                        />
                                    </figure>
                                )}
                                <div>
                                    {footer.pre_footer.dakcheck.title && (
                                        <p className="pre-footer__dakcheck__title">
                                            {footer.pre_footer.dakcheck.title}
                                        </p>
                                    )}
                                    {footer.pre_footer.dakcheck.text && (
                                        <div
                                            className="pre-footer__dakcheck__text"
                                            dangerouslySetInnerHTML={{
                                                __html: footer.pre_footer.dakcheck.text,
                                            }}
                                        />
                                    )}
                                    {footer.pre_footer.dakcheck.phone && (
                                        <p className="pre-footer__dakcheck__phone pb-20">
                                            <a href={`tel:${footer.pre_footer.dakcheck.phone}`}>
                                                {footer.pre_footer.dakcheck.phone}
                                            </a>
                                        </p>
                                    )}
                                    {footer.pre_footer.dakcheck.link && (
                                        <Button
                                            button={footer.pre_footer.dakcheck.link}
                                            classes="button button--link"
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    )}

                    {footer.pre_footer.magazine && (
                        <div className="bit-xxs-12 bit-sm-8 bit-md-6 pt-0 pb-0 mb-30">
                            <div className="pre-footer__magazine">
                                <div>
                                    {footer.pre_footer.magazine.title && (
                                        <p className="pre-footer__magazine__title">
                                            {footer.pre_footer.magazine.title}
                                        </p>
                                    )}
                                    {footer.pre_footer.magazine.text && (
                                        <div
                                            className="pre-footer__magazine__text pb-20"
                                            dangerouslySetInnerHTML={{
                                                __html: footer.pre_footer.magazine.text,
                                            }}
                                        />
                                    )}
                                </div>
                                <div>
                                    {footer.pre_footer.magazine.button && (
                                        <Button
                                            button={footer.pre_footer.magazine.button}
                                            classes="button"
                                        />
                                    )}
                                </div>
                                {footer.pre_footer.magazine.image && (
                                    <figure className="pre-footer__magazine__image">
                                        <ResponsiveImage image={footer.pre_footer.magazine.image}/>
                                    </figure>
                                )}
                            </div>
                        </div>
                    )}

                    {footer.pre_footer.blog && (
                        <div className="bit-xxs-12 bit-sm-8 bit-md-6 pt-0 pb-0 mb-30">
                            <div className="pre-footer__blog">
                                <div>
                                    {footer.pre_footer.blog.title && (
                                        <p className="pre-footer__blog__title">
                                            {footer.pre_footer.blog.title}
                                        </p>
                                    )}
                                    {footer.pre_footer.blog.text && (
                                        <div
                                            className="pre-footer__blog__text pb-20"
                                            dangerouslySetInnerHTML={{
                                                __html: footer.pre_footer.blog.text,
                                            }}
                                        />
                                    )}
                                </div>
                                <div>
                                    {footer.pre_footer.blog.button && (
                                        <Button
                                            button={footer.pre_footer.blog.button}
                                            classes="button button--green-light"
                                        />
                                    )}
                                </div>
                                {footer.pre_footer.blog.image && (
                                    <figure className="pre-footer__blog__image">
                                        <ResponsiveImage
                                            image={footer.pre_footer.blog.image}
                                            classes="pre-footer__blog__image__img"
                                            layout="fill"
                                        />
                                    </figure>
                                )}
                            </div>
                        </div>
                    )}

                    {footer.pre_footer.share && (
                        <div className="bit-xxs-12 bit-sm-10 bit-md-8 bit-lg-6 pt-0 pb-md-50">
                            <div
                                className={`pre-footer__share pre-footer__share--${footer.pre_footer.share.color}`}
                            >
                                <p className="pb-10 pb-sm-0">{footer.pre_footer.share.title}</p>
                                <div className="flex">
                                    {footer.pre_footer.share.links.map((link) => {
                                        return (
                                            <a
                                                href={link.url}
                                                target="_blank"
                                                rel="noreferrer"
                                                key={link.url}
                                            >
                                                <i className={link.icon}/>
                                            </a>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                {footer.pre_footer.projects && footer.pre_footer.projects.length && (
                    <div className="frame frame--justify-sm-center m-0 pt-75 pb-75 pb-md-50 pt-md-50">
                        <div className="bit-xxs-12 p-0">
                            <p className="h4 text--weight-demi text-center pb-30 pb-md-40">
                                {footer.pre_footer.projects_title}
                            </p>
                        </div>
                        <div className="bit-xxs-12 bit-sm-8 bit-md-10 bit-lg-9 p-0">
                            <Swiper
                                modules={[Pagination]}
                                spaceBetween={swiperSettings.spaceBetween}
                                slidesPerView={swiperSettings.slidesPerView}
                                breakpoints={swiperSettings.breakpoints}
                                pagination={{clickable: true}}
                            >
                                {footer.pre_footer.projects.map((project) => {
                                    return (
                                        <SwiperSlide key={project.url}>
                                            <Link href={project.url}>
                                                <div className="pre-footer__project text-center pb-20 pb-md-0">
                                                    {project.image && (
                                                        <figure className="pre-footer__project__image mb-20">
                                                            <ResponsiveImage
                                                                image={project.image}
                                                                classes="pre-footer__project__image__img"
                                                                layout="fill"
                                                            />
                                                        </figure>
                                                    )}
                                                    {project.title && (
                                                        <p className="pre-footer__project__title h5 text--weight-demi pb-20 pb-sm-30">
                                                            {project.title}
                                                        </p>
                                                    )}
                                                    <button>{project.button_text}</button>
                                                </div>
                                            </Link>
                                        </SwiperSlide>
                                    );
                                })}
                            </Swiper>
                        </div>
                    </div>
                )}

                {footer.pre_footer.logo_slider && footer.pre_footer.logo_slider.logos && (
                    <div className="pt-50 pb-10 pt-md-75 pb-md-30 pt-lg-100 pb-lg-50">
                        {footer.pre_footer.logo_slider.text && (
                            <div
                                className="text-center text--gray text--weight-normal pb-10 pb-md-30"
                                dangerouslySetInnerHTML={{
                                    __html: footer.pre_footer.logo_slider.text,
                                }}
                            />
                        )}
                        <div className="frame frame--justify-xxs-center m-0">
                            <div className="bit-xxs-8 bit-sm-10 bit-lg-12 p-0">
                                <LogoSlider logos={footer.pre_footer.logo_slider.logos}/>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
