import {countryEmail, countryWhatsapp} from "../lib/countryConfig";

export default function Quicklinks({targetAudience}) {
    return (
        <div className={`quicklinks ${targetAudience === 'business' ? 'quicklinks--brown' : ''}`}>
            <a href={"mailto:" + countryEmail}>
                <i className="fas fa-envelope"/>
            </a>
            {countryWhatsapp && (
                <a href={countryWhatsapp} target="_blank">
                    <i className="fab fa-whatsapp tw-text-[24px]"/>
                </a>
            )}
        </div>
    );
}
