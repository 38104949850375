import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import ResponsiveImage from "./modules/responsive-image";

const swiperSettings = {
  slidesPerView: 1,
  loop: true,
  breakpoints: {
    768: {
      slidesPerView: 3,
    },
    1024: {
      slidesPerView: 4,
    },
    1440: {
      slidesPerView: 6,
    },
  },
};

export default function LogoSlider({ logos }) {
  return (
    <div className="logo-slider">
      <Swiper
        modules={[Pagination, Navigation]}
        slidesPerView={swiperSettings.slidesPerView}
        loop={swiperSettings.loop}
        breakpoints={swiperSettings.breakpoints}
        navigation
      >
        {logos.length &&
          logos.map((logo) => {
            return (
              <SwiperSlide key={logo.src}>
                <figure className="logo-slider__image">
                  <ResponsiveImage
                    image={logo}
                    classes="logo-slider__image__img"
                    layout="fill"
                  />
                </figure>
              </SwiperSlide>
            );
          })}
      </Swiper>
    </div>
  );
}
