import Head from 'next/head'
import {htmlDecode} from "../lib/he";

export function buildSeoTitle(obj) {
    if (obj) {
        return obj.title ? htmlDecode(obj.title) : process.env.npm_package_name;
    }
}

export function buildSeoSchema(obj) {
    if (obj && obj.schema) {
        return {
            type: 'application/ld+json',
            json: JSON.stringify(obj.schema),
        }
    }
}

export default function SeoHead({obj}) {
    const seoSchema = buildSeoSchema(obj)

    return (
        <Head>
            <title>{buildSeoTitle(obj)}</title>
            <script type={seoSchema.type}>{seoSchema.json}</script>
            {obj.metadesc && <meta name="description" content={obj.metadesc}/>}

            {
                obj.open_graph_type &&
                <meta property="og:type" content={obj.open_graph_type}/>
            }
            {
                obj.open_graph_title &&
                <meta name="og:title" property="og:title" content={htmlDecode(obj.open_graph_title)}/>
            }
            {
                obj.open_graph_description &&
                <meta name="og:description" property="og:description" content={obj.open_graph_description}/>
            }
            {
                obj.site_name &&
                <meta property="og:site_name" content={obj.site_name}/>
            }
            {
                obj.url &&
                <meta property="og:url" content={obj.url}/>
            }
            {
                obj.twitter_card &&
                <meta name="twitter:card" content={obj.twitter_card}/>
            }
            {
                obj.twitter_title &&
                <meta name="twitter:title" content={obj.twitter_title}/>
            }
            {
                obj.twitter_image &&
                <meta name="twitter:image" content={obj.twitter_image}/>
            }
            {
                obj.twitter_description &&
                <meta name="twitter:description" content={obj.twitter_description}/>
            }
            {
                obj.twitter_site &&
                <meta name="twitter:site" content={obj.twitter_site}/>
            }
            {
                obj.twitter_creator &&
                <meta name="twitter:creator" content={obj.twitter_creator}/>
            }
            {
                obj.open_graph_image &&
                <meta property="og:image" content={obj.open_graph_image}/>
            }
            {
                obj.open_graph_image_width &&
                <meta property="og:image:width" content={obj.open_graph_image_width}/>
            }
            {
                obj.open_graph_image_height &&
                <meta property="og:image:height" content={obj.open_graph_image_height}/>
            }
            {
                obj.published_time &&
                <meta property="article:published_time" content={obj.published_time}/>
            }
            {
                obj.modified_time &&
                <meta property="article:modified_time" content={obj.modified_time}/>
            }
            {
                obj.locale &&
                <meta property="og:locale" content={obj.locale}/>
            }
            {
                obj.robots &&
                <meta name="robots" content={obj.robots}/>
            }
            <link rel="icon" type="image/png" href="/images/favicon.png" />

            {/*<link rel="apple-touch-icon" href="/static/images/favicon.ico" />*/}
            {/*<link rel="stylesheet" href="" />*/}
            {/*<link rel="canonical" href="" />*/}
        </Head>
    )
}