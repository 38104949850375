import Link from "next/link";
import {useEffect, useState} from "react";
import {useRouter} from "next/router";
import NavbarTopbar from "./navbar-topbar";
import {countryMySamSamLink} from "../lib/countryConfig";

export default function NavbarDesktop({menus, header, targetAudience}) {
    const router = useRouter();

    const [isHidden, setIsHidden] = useState(false);

    let previousScroll = 0;

    const controlNavbar = () => {
        let currentScroll = window.scrollY;

        if (window.scrollY < 250) {
            setIsHidden(false);
        } else {
            if (currentScroll > 0) {
                if (window.scrollY >= previousScroll) {
                    setIsHidden(true);
                } else {
                    setIsHidden(false);
                }
            }
        }

        previousScroll = window.scrollY;
    };

    useEffect(() => {
        window.addEventListener("scroll", controlNavbar);
        return () => {
            window.removeEventListener("scroll", controlNavbar);
        };
    }, []);

    return (
        <div
            className={`navbar-desktop ${isHidden && "navbar-desktop--is-hidden"}`}
        >
            <NavbarTopbar header={header} targetAudience={targetAudience}/>
            <div className="position--relative">
                <span className="navbar-desktop__logo-shadow"/>
                <div className="navbar-desktop__bar">
                    <ul className="tw-flex-wrap tw-w-[45%]">
                        {menus["main-menu-left"].items.map((item) => {
                            return (
                                <li key={item.url}>
                                    <Link href={item.url} target={item.target}>
                                        <a className={router.asPath === item.url ? "current" : ""}>
                                            {item.title}
                                        </a>
                                    </Link>
                                </li>
                            );
                        })}
                    </ul>
                    <div className="navbar-desktop__logo">
                        <Link href="/">
                            <a onMouseEnter={() => setIsHidden(false)}/>
                        </Link>
                    </div>
                    <ul>
                        {menus["main-menu-right"].items.map((item) => {
                            return (
                                <li key={item.url}>
                                    <Link href={item.url} target={item.target}>
                                        <a
                                            className={router.asPath === item.url ? "current" : ""}
                                        >
                                            {item.title}
                                        </a>
                                    </Link>
                                </li>
                            );
                        })}
                        {countryMySamSamLink.url &&
                            <li>
                                <Link href={countryMySamSamLink.url}>
                                    <a>
                                        <i className="fas fa-user-circle"/> {countryMySamSamLink.title}
                                    </a>
                                </Link>
                            </li>
                        }
                    </ul>
                </div>
            </div>
        </div>
    );
}
