import {useContext, useEffect, useState} from "react";
import GeneralContext from "../store/general";
import Link from "next/link";
import NavbarTopbar from "./navbar-topbar";
import {countryMySamSamLink} from "../lib/countryConfig";

export default function NavbarMobile({header, targetAudience}) {
    const [isHidden, setIsHidden] = useState(false);

    const generalContext = useContext(GeneralContext);

    let previousScroll = 0;

    const controlNavbar = () => {
        let currentScroll = window.scrollY;

        if (window.scrollY < 250) {
            setIsHidden(false);
        } else {
            if (currentScroll > 0) {
                if (window.scrollY >= previousScroll) {
                    setIsHidden(true);
                } else {
                    setIsHidden(false);
                }
            }
        }

        previousScroll = window.scrollY;
    };

    useEffect(() => {
        window.addEventListener("scroll", controlNavbar);
        return () => {
            window.removeEventListener("scroll", controlNavbar);
        };
    }, []);

    const logoClickHandler = (e) => {
        if (isHidden) {
            e.preventDefault();
            setIsHidden(false);
        }
    };

    const toggleMobileMenu = () => {
        generalContext.toggleMobileMenu();
    };

    return (
        <div className={`navbar-mobile ${isHidden ? "navbar-mobile--is-hidden" : ''}`}>
            <NavbarTopbar header={header} targetAudience={targetAudience}/>
            <div className="position--relative">
                <span className="navbar-mobile__logo-shadow"/>
                <div className="navbar-mobile__bar">
                    {countryMySamSamLink.url &&
                        <Link href={countryMySamSamLink.url}>
                            <a className="navbar-mobile__account-link">
                                <i className="fas fa-user-circle"/>
                            </a>
                        </Link>
                    }
                    <Link href="/">
                        <a className="navbar-mobile__logo" onClick={logoClickHandler}/>
                    </Link>
                    <button onClick={toggleMobileMenu} className="navbar-mobile__open-menu">
                        <i className="fas fa-bars"/>
                    </button>
                </div>
            </div>
        </div>
    );
}
