const he = require('he');

export function htmlEncode(string) {
    if (typeof string !== 'string') {
        return string;
    }

    return he.encode(string);
}

export function htmlDecode(string) {
    if (typeof string !== 'string') {
        return string;
    }

    return he.decode(string);
}