import { createContext, useState } from "react";

const GeneralContext = createContext({
  menus: {},
  mobileMenuOpen: false,
  toggleMobileMenu: (currentStatus) => {},
  breadcrumbs: [],
});

export function GeneralContextProvider(props) {
  const [menus, setMenus] = useState({});
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [breadcrumbs, setBreadcrumbs] = useState([]);

  function setBreadcrumbsHandler(newBreadcrumbs) {
    setBreadcrumbs(newBreadcrumbs);
  }

  function toggleMobileMenuHandler() {
    setMobileMenuOpen((currentStatus) => !currentStatus);
  }

  const context = {
    menus: menus,
    mobileMenuOpen: mobileMenuOpen,
    toggleMobileMenu: toggleMobileMenuHandler,
    breadcrumbs: breadcrumbs,
    setBreadcrumbs: setBreadcrumbsHandler,
  };

  return (
    <GeneralContext.Provider value={context}>
      {props.children}
    </GeneralContext.Provider>
  );
}

export default GeneralContext;
