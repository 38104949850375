import Link from "next/link";

export default function Button({ button, classes }) {
  return (
    <Link href={button.url} passHref>
      <a target={button.target} className={classes}>
        {button.title}
      </a>
    </Link>
  );
}
