import Link from "next/link";

export default function NavbarTopbar({header, targetAudience}) {
    return (
        header.consumer_homepage_link && header.consumer_homepage_link &&
        <div className="navbar-topbar">
            {header.consumer_homepage_link && <Link href={header.consumer_homepage_link.url} passHref>
                <a target={header.consumer_homepage_link.target}
                   className={targetAudience === 'consumer' ? 'active' : ''}>
                    {header.consumer_homepage_link.title}
                </a>
            </Link>}
            {header.business_homepage_link && <Link href={header.business_homepage_link.url} passHref>
                <a target={header.business_homepage_link.target}
                   className={targetAudience === 'business' ? 'active' : ''}>
                    {header.business_homepage_link.title}
                </a>
            </Link>}
        </div>
    )
}